html {
  min-height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100%;
  background-position: bottom;
  background-color: #fafafa;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  padding: 20px;
  margin-top: 60px;
}

form button, form h5 {
  margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #1565c0;
  box-shadow: none;
}

.header-logo{
  background: url(./img/favicon.png) no-repeat center;
  background-size: 75% 75%;
  background-color: white;
  margin-top: 1vh;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 250px;
  width: 250px;
  border: 5px solid #f2f20c;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
}

.header-logo span {
  width: 250px;
  align-self: flex-end;
  padding-bottom: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
  font-weight: bolder;
  font-size: xx-large;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  color: black;
}

.vertical {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-logo-1 {
  background-size: 75% 75%;
  background-color: white;
  position: absolute;
  left: 350px;
  top: 25px;
  height: 300px;
  width: 300px;
  border: 10px solid #0d47a1;
  border-radius: 50%;
  display: flex;
  padding: 0px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.header-logo-1 img {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 992px)
{
  .header-logo-1 {
    position: static;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-29px);
  }
}

.slogan {
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.title-text {
  font-size: 3rem;
  font-weight: bold;
  margin-left: 2rem;
}

.hero-image {
	background: url(./img/background2.jpg) no-repeat; 
  background-position: 0% 25%;
	background-size: cover;
	height: 350px;
}

.nav-link {
  width: 220px;
  text-align: center;
  font-size: x-large;
  transition: font-size 0.3s;
}

.side-nav-logo{
  background: url(./img/favicon.png) no-repeat center;
  background-size: 75% 75%;
  background-color: white;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 20px;
  height: 200px;
  width: 200px;
  border: 5px solid #f2f20c;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.nav-link-1 {
  box-shadow: 0px -7px #ffff00 inset;
}

.nav-link-2 {
  box-shadow: 0px -7px #d50000 inset;
}

.nav-link-3 {
  box-shadow: 0px -7px #0d47a1 inset;
}

.nav-link-4 {
  box-shadow: 0px -7px #76ff03 inset;
}

.nav-link-5 {
  box-shadow: 0px -7px #e65100 inset,
              0px  7px #e65100 inset;
  margin-bottom: 50px;
  padding-top: 5px;
}

.nav-link-6 {
  padding-bottom: 10px;
}

.nav-link-7 {
  box-shadow: 0px -7px #e65100 inset;
}

.parallax-container {
  height: 350px;
}

.shadow {
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.5),
              0 -4px 3px rgba(0, 0, 0, 0.2);
}

.btn-kennis {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  z-index: 10;
}

.side-nav-title {
  color: black;
}

.sidenav-form {
  margin: 0;
}

.sidenav-underline {
  border-bottom: 1px solid #212121 !important;
  margin: 0 !important;
}

Footer {
  margin-top: 5rem;
}

.capitalize {
  text-transform: capitalize;
}

.bold-text {
  font-weight: bold;
}

.welcome-img {
  height: 100%;
  width: 50% !important;
  position: absolute;
  right: 0 !important;
  top: 0;
}

.welcome-img img {
  border-radius: 20px;
  border: 2px solid #9e9e9e;
}

h4 {
  font-weight:900;
  color: #383838;
  margin-bottom: 3rem;
}

h5 {
  font-weight:600;
  color: #383838;
  padding-top: 2rem;
}

p {  
  color: #5c5c5c;
  line-height: 1.7;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.missie-img {
  height: 100%;
  width: 50% !important;
  position: absolute;
}

.missie-img img {
  margin-top: 3rem;
  height: 50%;
  object-fit: cover;
  border-radius: 20px;
  border: 2px solid #9e9e9e;
}

.list {
  list-style-type: disc !important;
  margin-left: 1rem;
}

@media only screen and (min-width: 993px) {
  .valign-large {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media only screen and (min-width: 601px) {
  .valign-medium {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.header, .main, footer {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
  .header, .main, footer {
    padding-left: 0;
  }
}

.navbar {
  margin-left: -300px;
}

@media only screen and (max-width : 992px) {
  .navbar {
    margin-left: 0px;
  }
}

.stretch-div-parent {
  display: flex;
}

.stretch-div {
  align-items: stretch;
}

.materialboxed {
  margin-left: auto;
  margin-right: auto;
  position: relative !important;
}

.card-title {
  margin: 0.5rem;
}

.collapsible-header {
  background-color: #ffa726;
}

.line-clamp{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.text-formatting{
  white-space: pre-line;
}

.border-event{
  border: 1px solid #000;
}

.tabs .tab a{
  background-color: #ffa726;
  color:#000;
} /*Black color to the text*/

.tabs .tab a:hover {
  background-color:#ff9800;
  color:#000;
} /*Text color on hover*/

.tabs .tab a.active {
  background-color:#fb8c00;
  color:#000;
} /*Background and text color when a tab is active*/

.tabs .tab a:focus.active {
  background-color:#fb8c00;
  color:#000;
} /*Background and text color when a tab is active*/

.tabs .indicator {
  background-color:#e65100;
} /*Color of underline*/

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  margin: 0;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  margin: 0;
}

/* Container holding the image and the text */
.groep-header {
  position: relative;
  text-align: center;
  color: white;
}

.groep-foto {
  width:100%;
  aspect-ratio: 8/3;
  display:flex;
  justify-content:center;
  overflow:hidden;
  object-fit:cover;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  width: 100%;
  padding: 25px;
}

.page-footer .footer-copyright {
  color: black;
}

.banner-splash {
  background: url(./img/banner_splash_3.png) center;
  background-size: cover;
}

.banner-block {
  padding: 32px;
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(50%); }
  to { -moz-transform: translateX(-50%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(50%); }
  to { -webkit-transform: translateX(-50%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(50%);
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  to {
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.blink-text {
  animation: blinker 5s linear infinite;
}

@keyframes blinker {
  5% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  15% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  25% {
    transform: scale(1);
  }
}